import "core-js/stable";
import "regenerator-runtime/runtime";

import './common.scss'
import 'bootstrap'

import icon_0 from './img/favicon.png';
import icon_1 from './img/touch-icon-57.png';
import icon_2 from './img/touch-icon-76.png';
import icon_3 from './img/touch-icon-100.png';
import icon_4 from './img/touch-icon-120.png';
import icon_5 from './img/touch-icon-152.png';
import icon_6 from './img/touch-icon-180.png';
import icon_7 from './img/touch-icon-167.png';

import Header from './components/Header.jsx';
import Main from './components/Main.jsx';
import About from './components/About.jsx';
import Footer from './components/Footer.jsx';

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Link } from "react-router-dom";

ReactDOM.render(
  <Router>
    <Header />
    <Route path="/" exact component={() => <Main api={ API } />} />
    <Route path="/about" component={() => <About api={ API } />} />
    <Footer />
  </Router>,
  document.getElementById('app-root')
);
