import React from "react";
import Button from 'react-bootstrap/Button';
import Process from './Process.jsx';
import Image from './Image.jsx';

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      "processes": ["total", "bbbar", "ttbar", "W", "Z", "ggH", "VBFH", "ttH",
      "VH", "tH", "HH"],
      "left_unit": "pb",
      "ppbar_pp": 4.00,
      "minor_lines": [1.96, 7, 8, 14],
      "major_lines": [13],
      "external_citation": false,
      "events_per_time": false,
      "lumi": "2e34",
      "proposed_ppbar_pp": 4.00,
      "proposed_minor_lines": [1.96, 7, 8, 14],
      "proposed_major_lines": [13],
      "proposed_lumi": "2e34",
      "span": "s",
    }
    this.state.processes.sort()

    this.updateProcess = this.updateProcess.bind(this);
    this.updateLeftUnit = this.updateLeftUnit.bind(this);
    this.updateSpan = this.updateSpan.bind(this);
    this.updateExternalCitation = this.updateExternalCitation.bind(this);
    this.updateMinorLines = this.updateMinorLines.bind(this);
    this.addMinorLines = this.addMinorLines.bind(this);
    this.deleteMinorLines = this.deleteMinorLines.bind(this);
    this.addMajorLines = this.addMajorLines.bind(this);
    this.deleteMajorLines = this.deleteMajorLines.bind(this);
    this.applyTextInput = this.applyTextInput.bind(this);
    setInterval(this.applyTextInput, 2000);
  }

  updateLeftUnit(value) {
    this.setState({"left_unit": value})
  }

  updateSpan(value) {
    this.setState({"span": value})
  }

  updateBeamTransition(value) {
    this.setState({"proposed_ppbar_pp": value})
  }

  applyTextInput() {
    this.setState({"ppbar_pp": this.filterInvalid(this.state.proposed_ppbar_pp),
                   "minor_lines": this.filterInvalids(this.state.proposed_minor_lines),
                   "major_lines": this.filterInvalids(this.state.proposed_major_lines),
                   "lumi": this.filterInvalidLumi(this.state.proposed_lumi)})
  }

  filterInvalids(a) {
    return a.map(this.filterInvalid)
  }

  filterInvalid(value) {
    value = parseFloat(value)
    if (value >= 0 && value <= 100)
      return value

    return 0
  }

  filterInvalidLumi(value) {
    value = parseFloat(value)
    if (value > 0) {
      return value
    }

    return 0
  }


  updateLumi(value) {
    this.setState({"proposed_lumi": value})
  }

  updateMinorLines(i, value) {
    let lines = [...this.state.minor_lines];
    lines[i] = value;
    this.setState({"proposed_minor_lines": lines})
  }
  deleteMinorLines(i) {
    let lines = this.state.minor_lines.filter((value, j) => i != j)
    this.setState({"proposed_minor_lines": this.filterInvalids(lines),
                   "minor_lines": this.filterInvalids(lines)})
  }
  addMinorLines() {
    let lines = [...this.state.minor_lines];
    if (lines.length > 0) {
      lines.push(lines[lines.length - 1])
    } else {
      lines.push(14)
    }
    this.setState({"proposed_minor_lines": this.filterInvalids(lines),
                   "minor_lines": this.filterInvalids(lines)})
  }
  deleteMajorLines(i) {
    let lines = this.state.major_lines.filter((value, j) => i != j)
    this.setState({"proposed_major_lines": this.filterInvalids(lines),
                   "major_lines": this.filterInvalids(lines)})
  }
  addMajorLines() {
    let lines = [...this.state.major_lines];
    if (lines.length > 0) {
      lines.push(lines[lines.length - 1])
    } else {
      lines.push(14)
    }
    this.setState({"proposed_major_lines": this.filterInvalids(lines),
                   "major_lines": this.filterInvalids(lines)})
  }

  updateMajorLines(i, value) {
    let lines = [...this.state.major_lines];
    lines[i] = value;
    this.setState({"proposed_major_lines": lines})
  }

  updateProcess(id, checked) {
    let processes = this.state.processes.filter(name => name != id)
    if (checked) {
      processes.push(id)
    }
    processes.sort()
    this.setState({"processes": processes}) 
  }

  updateExternalCitation(checked) {
    this.setState({"external_citation": checked}) 
  }

  updateEventsPerTime(checked) {
    this.setState({"events_per_time": checked}) 
  }

  args(force_download) {
    let state = {
      "processes": this.state.processes,
      "left_unit": this.state.left_unit,
      "ppbar_pp": this.state.ppbar_pp,
      "minor_lines": this.state.minor_lines,
      "major_lines": this.state.major_lines,
      "external_citation": this.state.external_citation,
      "download": force_download,
      "right_lumi": this.state.events_per_time ? this.state.lumi : 0,
      "time": this.state.span,
    }
    return encodeURI(JSON.stringify(state))
  }

  url(format, force_download=false) {
    return this.props.api + "/lhc-xsecs." + format + "?" + this.args(force_download)
  }

  alt() {
    return "LHC cross section overview plot with " + this.state.processes.join(", ") + "."
  }

  render() {
	return (
  <main className="container">
    <div className="row">
        <div className="col-md">
          <div>
            <a href={ this.url("pdf", true) }>
              <Image className="img-fluid" src={ this.url("svg") } alt={ this.alt() }/>
            </a>
          </div>
          <div className="text-center">
            <a className="btn btn-primary mr-1" href={this.url("pdf", true)} >.pdf</a>
            <a className="btn btn-outline-secondary mr-1" href={this.url("eps", true)} >.eps</a>
            <a className="btn btn-outline-secondary mr-1" href={this.url("svg", true)} >.svg</a>
            <a className="btn btn-outline-secondary" href={this.url("png", true)} >.png</a>
          </div>
          { this.state.external_citation && 
            <div className="alert alert-warning m-2">
              If you select the option <span style={{fontStyle:
              'italic'}}>external referencs</span>, please cite the relevant
              sources when using the plot.
            </div>
          }
          <div className="alert alert-secondary m-2">
            Please cite as (or similar):<br />
            <span className="text-muted">F. Sauerburger, <span
            style={{fontStyle: 'italic'}}>LHC cross section plot</span>,
            https://lhc-xsecs.org/</span>
          </div>
        </div>
        <div className="col-md">
          <form className="p-3">

            <div className="row form-group">
              <label className="col-md-4 col-form-label" htmlFor="left_unit">Cross section unit</label>
              <div className="col-md-8">
                <select value={this.state.left_unit} className="form-control" id="left_unit"
                        onChange={evt => this.updateLeftUnit(evt.target.value)}>
                  <option value="b">b</option>
                  <option value="mb">mb</option>
                  <option value="ub">μb</option>
                  <option value="nb">nb</option>
                  <option value="pb">pb</option>
                  <option value="fb">fb</option>
                  <option value="ab">ab</option>
                </select>
              </div>
            </div>
		
            <div className="row form-group">
              <label className="col-md-4 col-form-label">Process</label>

              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <Process id="total" processes={this.state.processes} onChange={this.updateProcess}>
                      <span style={{fontStyle: "normal"}}>Total</span>
                    </Process>
                    <Process id="bbbar" processes={this.state.processes} onChange={this.updateProcess}>
                      b<span style={{"textDecoration": "overline"}}>b</span>
                    </Process>
                    <Process id="ttbar" processes={this.state.processes} onChange={this.updateProcess}>
                      t<span style={{"textDecoration": "overline"}}>t</span>
                    </Process>
                    <Process id="W" processes={this.state.processes} onChange={this.updateProcess}>
                      W
                    </Process>
                    <Process id="Z" processes={this.state.processes} onChange={this.updateProcess}>
                      Z
                    </Process>
                    <Process id="WW" processes={this.state.processes} onChange={this.updateProcess}>
                      WW
                    </Process>
                    <Process id="ZZ" processes={this.state.processes} onChange={this.updateProcess}>
                      ZZ
                    </Process>
                    </div><div className="col-md-6">
                    <Process id="ggH" processes={this.state.processes} onChange={this.updateProcess}>
                      ggH
                    </Process>
                    <Process id="VBFH" processes={this.state.processes} onChange={this.updateProcess}>
                      <span style={{"fontStyle": "normal"}}>VBF</span>H
                    </Process>
                    <Process id="ttH" processes={this.state.processes} onChange={this.updateProcess}>
                      t<span style={{"textDecoration": "overline"}}>t</span>H
                    </Process>
                    <Process id="bbH" processes={this.state.processes} onChange={this.updateProcess}>
                      b<span style={{"textDecoration": "overline"}}>b</span>H
                    </Process>
                    <Process id="VH" processes={this.state.processes} onChange={this.updateProcess}>
                        VH
                    </Process>
                    <Process id="WH" processes={this.state.processes} onChange={this.updateProcess}>
                        WH
                    </Process>
                    <Process id="ZH" processes={this.state.processes} onChange={this.updateProcess}>
                        ZH
                    </Process>
                    <Process id="tH" processes={this.state.processes} onChange={this.updateProcess}>
                      tH
                    </Process>
                    <Process id="HH" processes={this.state.processes} onChange={this.updateProcess}>
                        HH
                    </Process>
                  </div>
                  <div>
                    <a href="mailto:frank@sauerburger.io?subject=lhc-xsecs.org">Something missing?</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-group">
              <label className="col-md-4 col-form-label">
                <span style={{"fontStyle": "italic"}}>p<span
                style={{"textDecoration": "overline"}}>p</span>-<span
                style={{"fontStyle": "italic"}}>pp</span></span> transition
              </label>
              <div className="col-md-8">
                <div className="form-row mx-0 my-1 align-items-center">
                    <input type="number" className="form-control col-6 text-right" id="ppbar_pp"
                           step="0.01" value={this.state.proposed_ppbar_pp} min="0" max="100"
                           onClick={evt => this.updateBeamTransition(evt.target.value)}
                           onBlur={evt => this.applyTextInput()}
                           onChange={evt => this.updateBeamTransition(evt.target.value)} />
                  <div className="col-6">TeV</div>
                </div>
              </div>
            </div>

            <div className="row form-group">
              <label className="col-md-4 col-form-label">Dotted lines</label>
              <div className="col-md-8">
                  { this.state.minor_lines.map( (value, i) => (
                      <div className="form-row mx-0 my-1 align-items-center" key={ "minor_line_" + i }>
                      <input type="number" className="form-control col-6 text-right" id="ppbar_pp"
                             step="0.01" value={this.state.proposed_minor_lines[i]} min="0" max="100"
                             onChange={evt => this.updateMinorLines(i, evt.target.value)}
                             onBlur={evt => this.applyTextInput()}
                             onClick={evt => this.updateMinorLines(i, evt.target.value)} />
                        <div className="col-4">TeV</div>
                        <button type="button" className="col-2 form-control btn btn-outline-danger"
                           onClick={() => this.deleteMinorLines(i)}>&times;</button>
                      </div>
                    ))
                  }
                  <button type="button" className="form-control btn btn-outline-success"
                         onClick={this.addMinorLines}>+</button>
              </div>
            </div>

            <div className="row form-group">
              <label className="col-md-4 col-form-label">Dashed lines</label>
              <div className="col-md-8">
                  { this.state.major_lines.map( (value, i) => (
                      <div className="form-row mx-0 my-1 align-items-center" key={ "major_line_" + i }>
                      <input type="number" className="form-control col-6 text-right" id="ppbar_pp"
                             step="0.01" value={this.state.proposed_major_lines[i]} min="0" max="100"
                             onChange={evt => this.updateMajorLines(i, evt.target.value)}
                             onBlur={evt => this.applyTextInput()}
                             onClick={evt => this.updateMajorLines(i, evt.target.value)} />
                        <div className="col-4">TeV</div>
                        <button type="button" className="col-2 form-control btn btn-outline-danger"
                           onClick={() => this.deleteMajorLines(i)}>&times;</button>
                      </div>
                    ))
                  }
                  <button type="button" className="form-control btn btn-outline-success"
                         onClick={this.addMajorLines}>+</button>
              </div>
            </div>

            <div className="row form-group align-items-baseline">
              <label className="col-md-4 col-form-label">References</label>
              <div className="form-check col-md-8">
                <input className="form-check-input" type="checkbox" id="external_citation"
                       checked={ this.state.external_citation } onChange={evt =>
                       this.updateExternalCitation(evt.target.checked)} />
                <label htmlFor="external_citation">external</label>
              </div>
            </div>

            <div className="row form-group align-items-baseline">
              <label className="col-md-4 col-form-label">Events / time</label>
              <div className="form-check col-md-8">
                  <input className="form-check-input" type="checkbox" id="events_per_time"
                         checked={ this.state.events_per_time }
                         onChange={evt => this.updateEventsPerTime(evt.target.checked)} />
                  <label htmlFor="events_per_time">enable</label>
              </div>
            </div>
            { this.state.events_per_time && 
              <div className="row form-group align-items-center">
                  <label className="col-md-4 col-form-label" htmlFor="lumi">Inst. luminosity</label>
                  <div className="mx-0 my-1 col-md-8 form-row align-items-baseline">
                      <input type="text" className="form-control col-4 text-right" id="lumi"
                             value={this.state.proposed_lumi}
                             onChange={evt => this.updateLumi(evt.target.value)}
                             onBlur={evt => this.applyTextInput()}
                             onClick={evt => this.updateLumi(evt.target.value)} />
                      <div className="col-4">
                        cm<sup>-2</sup> s<sup>-1</sup>
                      </div>
                  </div>
              </div>
            }
            { this.state.events_per_time && 
              <div className="row form-group">
                  <label className="col-md-4 col-form-label" htmlFor="span">Time span</label>
                  <div className="col-md-8">
                    <select value={this.state.span} className="form-control" id="span"
                            onChange={evt => this.updateSpan(evt.target.value)}>
                      <option value="s">s</option>
                      <option value="min">min</option>
                      <option value="h">h</option>
                      <option value="day">day</option>
                      <option value="week">week</option>
                      <option value="month">month</option>
                      <option value="year">year</option>
                    </select>
                  </div>
              </div>
            }
          </form>
        </div>
    </div>
  </main>
)
}
}

export default Main
