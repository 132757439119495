
import React from "react";
import classNames from "classnames";

class Image extends React.Component {
  constructor(props) {
    super(props)
    this.state = {loading: true}
    this.imageLoaded = this.imageLoaded.bind(this)
  }

  imageLoaded() {
    this.setState({loading: false})
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.src != this.props.src) {
      this.setState({loading: true})
    }
  }

  render() {
    return (<img className={
                   classNames(this.props.className,
                              {"loading": this.state.loading})
                 }
                 alt={this.props.alt}
                 style={this.props.style}
                 onLoad={() => this.imageLoaded()}
                 src={this.props.src} />)
  }
}

export default Image
