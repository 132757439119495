import React from "react";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";

const Header = () => (
  <header className="navbar navbar-expand navbar-dark bg-dark">
    <nav className="container">
      <a className="navbar-brand" href="/">
        <img src={logo} alt="lhc-xsecs.org" style={{height: "52px", width: "236px"}} />
      </a>
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link" to="/about">About</Link>
        </li>
      </ul>
    </nav>
  </header>
)

export default Header
