import React from "react";
import { Link } from "react-router-dom";

const Header = () => (
  <footer className="mt-auto bg-light">
    <div className="container text-muted text-right py-2">
      &copy;2020-{new Date().getFullYear()} <a href="mailto:frank@sauerburger.io">Frank Sauerburger</a>,
      plots released under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY 4.0</a>.
    </div>
  </footer>
)

export default Header
