
import React from "react";

const Process = ({id, children, processes, onChange}) => (
  <div className="form-check">
    <input className="form-check-input" type="checkbox" id={"process_" + id }
           checked={ processes.includes(id) } onChange={evt => onChange(id, evt.target.checked)} />
    <label className="form-check-label" htmlFor={"process_" + id } style={{fontStyle: "italic"}}>
      { children  }
    </label>
  </div>
);

export default Process
