import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const About = ({api}) => (
  <main className="container p-3">
    <Helmet>
      <title>About &ndash; LHC cross section plot</title>
    </Helmet>
    <div className="row">
      <div className="col-md-6 text-center">
        <figure className="figure">
          <img className="img-fluid"
            alt="LHC cross section overview plot with HH, VBFH, VH, W, Z, bbbar, ggH, total, ttbar."
            src={ api + "/lhc-xsecs.svg?%7B%22processes%22:%5B%22HH%22,%22VBFH%22,%22VH%22,%22W%22,%22Z%22,%22bbbar%22,%22ggH%22,%22total%22,%22ttbar%22%5D,%22left_unit%22:%22fb%22,%22ppbar_pp%22:0,%22minor_lines%22:%5B7,8,14%5D,%22major_lines%22:%5B13%5D,%22external_citation%22:false%7D" } />
        </figure>
      </div>
      <div className="col-md-6">
        <p className="lead text-muted">
          The purpose of this website is to provide custom cross section
          overview plots. 
        </p>
        <p>
          I'm a physicist and have used the famous <a
          href="http://www.hep.ph.ic.ac.uk/~wstirlin/plots/crosssections2013.jpg">cross section plot by W. J.
          Stirling</a> for my talks, publications, and posters. However, at some
          point, I wanted to change three things:
        </p>
        <ul>
          <li>Put the LHC line to 13 TeV,</li>
          <li>Add more Higgs processes and</li>
          <li>Extend the plot to higher energies.</li>
        </ul>
        <p>
          The result of this mini-project is a new version of the plot and this website: You can customize the
          cross section overview plots, download them and use them for your
          talks, publications, and posters.
        </p>
        <p>
          The total cross section is computed using the COMPETE HPR1R2 model
          [1]. For all other processes, the cross section was computed using
          MG5_aMC@NLO [2]. As indicated in the plot, some of the processes
          were normalized (scaled) by high-precision predictions or
          measurements at a fixed center-of-mass energy.
        </p>
        <p>
          The plots are released used the <a
          href="https://creativecommons.org/licenses/by/4.0/">Creative
          Commons Attribution 4.0 International</a> license. If the plot is
          used in talks, publications or posters, please use the reference <br />
          <span className="text-muted">F. Sauerburger, <span
          style={{fontStyle: 'italic'}}>LHC cross section
          plot</span>, https://lhc-xsecs.org/</span>
        </p>
        <p>
          Although this plot has been created with the greatest care, I assume no
          responsibility or liability for any errors or omissions in the content
          of this site. The information contained in this site is provided on an
          "as is" basis with no guarantees of completeness, accuracy, usefulness
          or timeliness.
        </p>
        <h3>Main references</h3>
        <ol>
          <li>
            C. Patrignani et al. (Particle Data Group), <span
            style={{fontStyle: 'italic'}}>Review of Particle Physics</span>, <a
            href="https://doi.org/10.1088/1674-1137/40/10/100001">Chin. Phys. C <span
            style={{fontWeight: 'bold'}}>40</span> 100001 (2016)</a>
          </li>
          <li>
            J. Alwall et al, <span
            style={{fontStyle: 'italic'}}>The automated computation of tree-level and next-to-leading order
            differential cross sections, and their matching to parton shower
            simulations</span>, <a
            href="https://doi.org/10.1007/JHEP07(2014)079">
            JHEP <span style={{fontWeight: 'bold'}}>07</span> (2014) 079</a>
          </li>
        </ol>
        <p>References of the normalization are specified directly in the
        plot.</p>
      </div>
    </div>
    <div className="terms">
      <h2>Legal notice</h2>
      <p>
        This website is developed, operated and owned by:
      </p>
      <address>
        Frank Sauerburger<br />
        Lameystr. 1<br />
        79108 Freiburg<br />
        Germany<br />
      </address>
      <p>
        Phone: +49 7668 3192560<br />
        Email: frank@sauerburger.io
      </p>
        <h2>Privacy Policy</h2>
        <h3>Introduction</h3>
        <ol>
          <li>We are committed to safeguarding the privacy of our website visitors.</li>
          <li>This policy applies where we are acting as a data controller with respect
          to the personal data of our website visitors; in other
          words, where we determine the purposes and means of the processing of that
          personal data.</li>
          <li>In this policy, "we", "us" and "our" refer to Frank Sauerburger.</li>
        </ol>
        
        <h3>Credit</h3>
        <p>This document was created using a template from <a
        href="https://seqlegal.com" rel="nofollow noreferrer noopener">SEQ
        Legal</a>.</p>
        
        <h3>How we use your personal data</h3>
        <ol>
          <li>In this Section 3 we have set out:
            <ol>
              <li>the general categories of personal data that we may process;</li>
              <li>the purposes for which we may process personal data; and</li>
              <li>the legal bases of the processing.</li>
            </ol>
          </li>
          <li>We may process data about your use of our website ("usage data"). The
          usage data may include your IP address, geographical location, browser type
          and version, operating system, referral source, length of visit, page views
          and website navigation paths, as well as information about the timing,
          frequency and pattern of your service use. Cookie information is not processed
          at the server. The source of the "usage data" is
          the web server's access log file. This usage data may be processed for the
          purposes of analyzing the use of the website and services. The legal basis for
          this processing is our legitimate interests, namely monitoring and improving
          our website and services.</li>
          <li>In addition to the specific purposes for which we may process your
          personal data set out in this Section 3, we may also process any of your
          personal data where such processing is necessary for compliance with a legal
          obligation to which we are subject, or in order to protect your vital
          interests or the vital interests of another natural person.</li>
          <li>Please do not supply any other person's personal data to us, unless we
          prompt you to do so.</li>
        </ol>
        
        <h3>Providing your personal data to others</h3>
        <p>We may disclose your personal data where such disclosure is necessary for
        compliance with a legal obligation to which we are subject, or in order to
        protect your vital interests or the vital interests of another natural person.
        We may also disclose your personal data where such disclosure is necessary for
        the establishment, exercise or defence of legal claims, whether in court
        proceedings or in an administrative or out-of-court procedure.</p>
        
        <h3>Retaining and deleting personal data</h3>
        <ol>
          <li>This Section 5 sets out our data retention policies and procedure, which
          are designed to help ensure that we comply with our legal obligations in
          relation to the retention and deletion of personal data.</li>
          <li>Personal data that we process for any purpose or purposes shall not be
          kept for longer than is necessary for that purpose or those purposes.</li>
          <li>Usage data will be retained for a period of 7 days.</li>
          <li>Notwithstanding the other provisions of this Section 5, we may retain your
          personal data where such retention is necessary for compliance with a legal
          obligation to which we are subject, or in order to protect your vital
          interests or the vital interests of another natural person.</li>
        </ol>
        <h3>Amendments</h3>
        <ol>
          <li>We may update this policy from time to time by publishing a new version on
          our website.</li>
          <li>You should check this page occasionally to ensure you are happy with any
          changes to this policy.</li>
        </ol>
        <h3>Your rights</h3>
        <ol>
          <li>In this Section 7, we have summarised the rights that you have under data
          protection law. Some of the rights are complex, and not all of the details
          have been included in our summaries. Accordingly, you should read the relevant
          laws and guidance from the regulatory authorities for a full explanation of
          these rights.</li>
          <li>Your principal rights under data protection law are:
            <ol>
              <li>the right to access;</li>
              <li>the right to rectification;</li>
              <li>the right to erasure;</li>
              <li>the right to restrict processing;</li>
              <li>the right to object to processing;</li>
              <li>the right to data portability;</li>
              <li>the right to complain to a supervisory authority; and</li>
              <li>the right to withdraw consent.</li>
            </ol>
          </li>
          <li>You have the right to confirmation as to whether or not we process your
          personal data and, where we do, access to the personal data, together with
          certain additional information. That additional information includes details
          of the purposes of the processing, the categories of personal data concerned
          and the recipients of the personal data. Providing the rights and freedoms of
          others are not affected, we will supply to you a copy of your personal data.
          The first copy will be provided free of charge, but additional copies may be
          subject to a reasonable fee.</li>
          <li>You have the right to have any inaccurate personal data about you
          rectified and, taking into account the purposes of the processing, to have any
          incomplete personal data about you completed.</li>
          <li>In some circumstances you have the right to the erasure of your personal
          data without undue delay. Those circumstances include: the personal data are
          no longer necessary in relation to the purposes for which they were collected
          or otherwise processed; you withdraw consent to consent-based processing; you
          object to the processing under certain rules of applicable data protection
          law; the processing is for direct marketing purposes; and the personal data
          have been unlawfully processed. However, there are exclusions of the right to
          erasure. The general exclusions include where processing is necessary: for
          exercising the right of freedom of expression and information; for compliance
          with a legal obligation; or for the establishment, exercise or defence of
          legal claims.</li>
          <li>In some circumstances you have the right to restrict the processing of
          your personal data. Those circumstances are: you contest the accuracy of the
          personal data; processing is unlawful but you oppose erasure; we no longer
          need the personal data for the purposes of our processing, but you require
          personal data for the establishment, exercise or defence of legal claims; and
          you have objected to processing, pending the verification of that objection.
          Where processing has been restricted on this basis, we may continue to store
          your personal data. However, we will only otherwise process it: with your
          consent; for the establishment, exercise or defence of legal claims; for the
          protection of the rights of another natural or legal person; or for reasons of
          important public interest.</li>
          <li>You have the right to object to our processing of your personal data on
          grounds relating to your particular situation, but only to the extent that the
          legal basis for the processing is that the processing is necessary for: the
          performance of a task carried out in the public interest or in the exercise of
          any official authority vested in us; or the purposes of the legitimate
          interests pursued by us or by a third party. If you make such an objection, we
          will cease to process the personal information unless we can demonstrate
          compelling legitimate grounds for the processing which override your
          interests, rights and freedoms, or the processing is for the establishment,
          exercise or defence of legal claims.</li>
          <li>To the extent that the legal basis for our processing of your personal data is:
            <ol>
              <li>consent; or</li>
              <li>that the processing is necessary for the performance of a contract to
              which you are party or in order to take steps at your request prior to
              entering into a contract,</li>
            </ol>
          and such processing is carried out by automated means, you have the right to
          receive your personal data from us in a structured, commonly used and
          machine-readable format. However, this right does not apply where it would
          adversely affect the rights and freedoms of others.</li>
          <li> If you consider that our processing of your personal information
          infringes data protection laws, you have a legal right to lodge a complaint
          with a supervisory authority responsible for data protection. You may do so in
          the EU member state of your habitual residence, your place of work or the
          place of the alleged infringement.</li>
          <li>To the extent that the legal basis for our processing of your personal
          information is consent, you have the right to withdraw that consent at any
          time. Withdrawal will not affect the lawfulness of processing before the
          withdrawal.</li>
          <li>You may exercise any of your rights in relation to your personal data by written notice to us.</li>
        </ol>
        <h3>About cookies</h3>
        <ol>
          <li>A cookie is a file containing an identifier (a string of letters and
          numbers) that is sent by a web server to a web browser and is stored by the
          browser. The identifier is then sent back to the server each time the browser
          requests a page from the server.</li>
          <li>Cookies may be either "persistent" cookies or "session" cookies: a
          persistent cookie will be stored by a web browser and will remain valid until
          its set expiry date, unless deleted by the user before the expiry date; a
          session cookie, on the other hand, will expire at the end of the user session,
          when the web browser is closed.</li>
          <li>Cookies do not typically contain any information that personally
          identifies a user, but personal information that we store about you may be
          linked to the information stored in and obtained from cookies.</li>
        </ol>
        <h3>Cookies that we use</h3>
        <p>We do not use cookies</p>

        <h3>Our details</h3>
        <ol>
          <li>This website is owned and operated by Frank Sauerburger.</li>
          <li>Our principal place of business is at Lameystr. 1, 79108 Freiburg, Germany.</li>
          <li>You can contact us:
            <ol>
              <li>by post, to the postal address given above;</li>
              <li>by telephone, on the contact number published on our website or</li>
              <li>by email, using the email address published on our website.</li>
            </ol>
          </li>
        </ol>
    </div>
  </main>
)

export default About
